<template>
  <!-- -->
  <div class="main">
    <div class="notice">
      <div style="flex: 2; margin: 0 20px 0 0">
        <div style="background: #ffffff">
          <div class="title" style="overflow: hidden; padding: 8px">
            <span>订单概况</span>
            <span style="float: right">
              <el-radio-group
                @change="drawChart"
                size="mini"
                v-model="orderDate"
              >
                <el-radio-button label="3">近7天</el-radio-button>
                <el-radio-button label="4">本月</el-radio-button>
                <el-radio-button label="5">上月</el-radio-button>
              </el-radio-group>
            </span>
          </div>
          <div class="revenue">
            <div class="content">
              <div style="flex: 1.7; margin: 5px 0 0 0">
                <div
                  id="main"
                  :style="{ width: '100%', height: '302px' }"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div style="background: #ffffff; margin: 16px 0 0 0; line-height: 30px">
          <div class="title" style="overflow: hidden; padding: 8px">
            <span>数据统计</span>
            <span style="float: right">
              <el-radio-group
                @change="handleChangeStatDate"
                size="mini"
                v-model="statDate"
              >
                <el-radio-button label="1">今天</el-radio-button>
                <el-radio-button label="2">昨天</el-radio-button>
                <el-radio-button label="3">本周</el-radio-button>
                <el-radio-button label="4">本月</el-radio-button>
                <el-radio-button label="5">上月</el-radio-button>
              </el-radio-group>
            </span>
          </div>
        </div>
        <div
          style="
            margin: 16px 0 0 0;
            line-height: 30px;
            display: flex;
            background: rgb(242, 242, 244);
          "
          class="stats"
        >
          <div style="flex: 1; padding: 0 8px 0 0">
            <div
              onmouseover="setBackground(this,'#e6f5fe')"
              onmouseout="setBackground(this,'#ffffff')"
              class="todo-item"
              style="margin: 0 0 17px 0; height: 152px"
            >
              <div class="todo-item-title">下单笔数</div>
              <div class="todo-item-num">{{ stat.xdbs }}</div>
            </div>
            <div
              onmouseover="setBackground(this,'#e6f5fe')"
              onmouseout="setBackground(this,'#ffffff')"
              class="todo-item"
              style="margin: 0 0 17px 0; height: 153px"
            >
              <div class="todo-item-title">下单金额（元）</div>
              <div class="todo-item-num">{{ stat.xdje }}</div>
            </div>
            <div
              onmouseover="setBackground(this,'#e6f5fe')"
              onmouseout="setBackground(this,'#ffffff')"
              class="todo-item"
              style="height: 152px"
            >
              <div class="todo-item-title">充值</div>
              <div class="todo-item-num">{{ stat.czje }}</div>
              <div>共{{ stat.czbs }}笔充值</div>
            </div>
          </div>
          <div style="flex: 1; padding: 0 8px 0 0">
            <div
              onmouseover="setBackground(this,'#e6f5fe')"
              onmouseout="setBackground(this,'#ffffff')"
              class="todo-item"
              style="margin: 0 0 17px 0"
            >
              <div class="todo-item-title">已付笔数 / 件数</div>
              <div class="todo-item-num">{{ stat.yfbs }} / {{stat.pics}}</div>
            </div>

            <div
              onmouseover="setBackground(this,'#e6f5fe')"
              onmouseout="setBackground(this,'#ffffff')"
              class="todo-item"
              style="margin: 0 0 17px 0"
            >
              <div class="todo-item-title">未付笔数</div>
              <div class="todo-item-num">{{ stat.wfbs }}</div>
            </div>

            <div
              onmouseover="setBackground(this,'#e6f5fe')"
              onmouseout="setBackground(this,'#ffffff')"
              class="todo-item"
              style="margin: 0 0 17px 0"
            >
              <div class="todo-item-title">退款笔数</div>
              <div class="todo-item-num">{{ stat.tkbs }}</div>
            </div>
            <div
              onmouseover="setBackground(this,'#e6f5fe')"
              onmouseout="setBackground(this,'#ffffff')"
              class="todo-item"
              style="margin: 0 0 17px 0; height: 152px"
            >
              <div class="todo-item-title">提现</div>
              <div class="todo-item-num">{{ stat.txje }}</div>
              <div>共{{ stat.txbs }}笔提现</div>
            </div>
          </div>
          <div style="flex: 1">
            <div
              onmouseover="setBackground(this,'#e6f5fe')"
              onmouseout="setBackground(this,'#ffffff')"
              class="todo-item"
              style="margin: 0 0 17px 0"
            >
              <div class="todo-item-title">已付金额（元）</div>
              <div class="todo-item-num">{{ stat.yfje }}</div>
            </div>
            <div
              onmouseover="setBackground(this,'#e6f5fe')"
              onmouseout="setBackground(this,'#ffffff')"
              class="todo-item"
              style="margin: 0 0 17px 0"
            >
              <div class="todo-item-title">未付金额（元）</div>
              <div class="todo-item-num">{{ stat.wfje }}</div>
            </div>
            <div
              onmouseover="setBackground(this,'#e6f5fe')"
              onmouseout="setBackground(this,'#ffffff')"
              class="todo-item"
              style="margin: 0 0 17px 0"
            >
              <div class="todo-item-title">退款金额（元）</div>
              <div class="todo-item-num">{{ stat.tkje }}</div>
            </div>
            <div
              onmouseover="setBackground(this,'#e6f5fe')"
              onmouseout="setBackground(this,'#ffffff')"
              class="todo-item"
              style="margin: 0 0 17px 0; height: 152px"
            >
              <div class="todo-item-title">会员增量</div>
              <div class="todo-item-num">{{ stat.hyzl }}</div>
              <div>共{{ stat.hyzs }}名会员</div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="system-notice"
        style="position: relative; flex: 1; margin: 0; background: #f2f2f4"
      >
        <div
          style="
            background: #ffffff;
            padding: 0px 8px 0 8px;
            margin: 0 0 16px 0;
          "
        >
          <div class="title" style="margin: 0 0 0 0; padding: 12px 8px 0 8px">
            待办事项
          </div>
          <div class="content">
            <div
              @click="handleJump('/finance/manage/rechargeManage')"
              style="
                display: flex;
                line-height: 40px;
                background: #ffeeef;
                font-size: 14px;
                cursor: pointer;
              "
            >
              <div
                style="
                  flex: 1;
                  border-left: #e74f51 solid 3px;
                  padding: 0 0 0 8px;
                "
              >
                充值待审核
              </div>
              <div style="flex: 2; text-align: right; padding: 0 39px 0 0">
                {{ todoStat.czbs
                }}<span style="color: #aaaaaa; margin: 0 0 0 5px">笔</span>
              </div>
              <div style="flex: 1; text-align: right; padding: 0 16px 0 0">
                {{ todoStat.czje
                }}<span style="color: #aaaaaa; margin: 0 0 0 5px">元</span>
              </div>
            </div>
            <div
              @click="handleJump('/order_mng/order/order', { status: 'pay' })"
              style="
                display: flex;
                line-height: 40px;
                background: #edf6fd;
                font-size: 14px;
                margin: 8px 0 0 0;
                cursor: pointer;
              "
            >
              <div
                style="
                  flex: 1;
                  border-left: #4934b2 solid 3px;
                  padding: 0 0 0 8px;
                "
              >
                订单待审核
              </div>
              <div style="flex: 2; text-align: right; padding: 0 39px 0 0">
                {{ todoStat.dshbs
                }}<span style="color: #aaaaaa; margin: 0 0 0 5px">笔</span>
              </div>
              <div style="flex: 1; text-align: right; padding: 0 16px 0 0">
                {{ todoStat.dshje
                }}<span style="color: #aaaaaa; margin: 0 0 0 5px">元</span>
              </div>
            </div>
            <div
              style="
                display: flex;
                line-height: 40px;
                background: #edf6fd;
                font-size: 14px;
                margin: 8px 0 0 0;
                cursor: pointer;
              "
              @click="
                handleJump('/order_mng/order/order', { status: 'produce' })
              "
            >
              <div
                style="
                  flex: 1;
                  border-left: #4934b2 solid 3px;
                  padding: 0 0 0 8px;
                "
              >
                排单情况
              </div>
              <div style="flex: 2; text-align: right; padding: 0 39px 0 0">
                {{ todoStat.pdbs
                }}<span style="color: #aaaaaa; margin: 0 0 0 5px">笔</span>
              </div>
              <div style="flex: 1; text-align: right; padding: 0 16px 0 0">
                {{ todoStat.pdje
                }}<span style="color: #aaaaaa; margin: 0 0 0 5px">元</span>
              </div>
            </div>
          </div>
        </div>
        <div style="background: #ffffff; padding: 0px 8px 0 8px">
          <div class="title" style="margin: 0 0 0 0; padding: 12px 8px 0 8px">
            <el-tabs v-model="activeName" @tab-click="getRankStat">
              <el-tab-pane label="充值排行" name="charge"></el-tab-pane>
              <el-tab-pane label="商家排行" name="merchant"></el-tab-pane>
            </el-tabs>
          </div>
          <div>
            <div style="display: flex">
              <div style="flex: 1">
                <el-input
                  suffix-icon="el-icon-search"
                  placeholder="商家名称/账号"
                  v-model="searchText"
                  @keyup.native="handleChangeText"
                  size="small"
                ></el-input>
              </div>

              <div style="flex: 1; text-align: right">
                <el-radio-group
                  @change="getRankStat"
                  size="small"
                  v-model="rankDate"
                >
                  <el-radio-button label="3">近7天</el-radio-button>
                  <el-radio-button label="4">本月</el-radio-button>
                  <el-radio-button label="5">上月</el-radio-button>
                </el-radio-group>
              </div>
            </div>
            <div v-if="activeName == 'charge'">
              <el-table
                :style="{ width: tableWidth + 'px' }"
                :data="chargeRankList"
              >
                <el-table-column
                  prop="noticeType"
                  label="排名"
                  width="100"
                  align="center"
                >
                  <template slot-scope="scope">
                    {{ scope.$index + 1 }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="noticeType"
                  label="商家名称"
                  align="center"
                >
                  <template slot-scope="scope">
                    {{ scope.row.userName }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="czbs"
                  label="充值笔数"
                  width="100"
                  align="center"
                >
                  <template slot-scope="scope">
                    {{ scope.row.czbs }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="czje"
                  label="充值金额"
                  width="129"
                  align="center"
                  ><template slot-scope="scope">
                    {{ scope.row.czje }}
                  </template></el-table-column
                >
              </el-table>
              <div v-if="false" class="pager">
                <el-pagination
                  @size-change="loadNoticeData"
                  @current-change="loadNoticeData"
                  :current-page.sync="page.pageNum"
                  :page-size="page.pageSize"
                  layout="prev, pager, next"
                  :total="page.total"
                >
                </el-pagination>
              </div>
            </div>
            <div v-if="activeName == 'merchant'">
              <el-table
                :style="{ width: tableWidth + 'px' }"
                :data="merchantRankList"
              >
                <el-table-column prop="noticeType" label="排名" align="center">
                  <template slot-scope="scope">
                    {{ scope.$index + 1 }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="noticeType"
                  label="商家名称"
                  align="center"
                >
                  <template slot-scope="scope">
                    {{ scope.row.userName }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="noticeType"
                  label="店铺数"
                  align="center"
                >
                  <template slot-scope="scope">
                    {{ scope.row.dpsl }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="noticeType"
                  label="产品数"
                  align="center"
                >
                  <template slot-scope="scope">
                    {{ scope.row.cpsl }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="noticeType"
                  label="下单数"
                  align="center"
                >
                  <template slot-scope="scope">
                    {{ scope.row.xdbs }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="noticeType"
                  label="下单金额"
                  align="center"
                >
                  <template slot-scope="scope">
                    {{ scope.row.xdje }}
                  </template>
                </el-table-column>
              </el-table>
              <div v-if="false" class="pager">
                <el-pagination
                  @size-change="loadNoticeData"
                  @current-change="loadNoticeData"
                  :current-page.sync="page.pageNum"
                  :page-size="page.pageSize"
                  layout="prev, pager, next"
                  :total="page.total"
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listNotice } from "@/api/system/notice"
import hot from "@/assets/images/index/hot.png"
import newImage from "@/assets/images/index/new.png"
import { mapGetters } from "vuex"
import {
  listNew,
  listSale,
  getOverview,
  getOrderNumWithDay,
  getIncomeStat,
  getLast7Days,
  getAdminStatWithDay,
  getAdminTodoWithDay,
  getAdminChargeWithDay,
  getAdminMerchantWithDay,
  getAdminOrderWithDay,
} from "@/api/customProduct/product"
import {
  loadTypeData,
  getTypeInfo,
  getTypeTree,
  addType,
  updateType,
  deleteType,
  getTypeTreeList,
} from "@/api/sysSupport/category"

// 引入 ECharts 主模块
var echarts = require("echarts/lib/echarts")
// 引入柱状图
require("echarts/lib/chart/bar")
// 引入提示框和标题组件
require("echarts/lib/component/tooltip")
require("echarts/lib/component/title")
import { listRecharge } from "@/api/business/fundManage"
import { listWithdraw } from "@/api/business/fundManage"

window.setBackground = function (dom, color) {
  dom.style.backgroundColor = color
}

export default {
  data() {
    return {
      activeName: "charge",
      tableData: [],
      searchText: "",
      page: {
        status: "0",
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      statDate: "1",
      stat: {},
      todoStat: {},
      rankDate: "3",
      chargeRankList: [],
      merchantRankList: [],
      chargeRankListBak: [],
      merchantRankListBak: [],
      orderData: [],
      orderDate: "3",
      tableWidth: 410,
      noticeDialogData: {},
      elNew: {
        background: '',
        color: '',
      },
      elHot: {
        background: '',
        color: '',
      },
    }
  },
  computed: {
    ...mapGetters(["dictData", "dictMap", "nginx", "user"]),
  },
  watch: {
    dateRange() {
      this.getOrderNumWithDay(this.dateRange)
    },
    user: {
      handler() {
        this.getStat()
      },
      deep: true,
    },
    selection() {
      // this.getIncomeStat()
    },
  },
  methods: {
    getTableWidth() {
      let width = 410
      let dom = document.querySelector(".system-notice")
      if (dom) {
        width = dom.offsetWidth - 15
      }
      this.tableWidth = width
    },
    handleClick() {},
    // 获取公告数据
    loadNoticeData() {
      listNotice(this.page)
        .then((res) => {
          if (res.code == 200) {
            this.tableData = res.rows
            if (res.rows.length > 0) {
              this.noticeDialogData.title = res.rows[0].noticeTitle
              this.noticeDialogData.content = res.rows[0].noticeContent
              this.noticeDialogData.noticeType = res.rows[0].noticeType
              this.noticeDialogData.createTime = res.rows[0].createTime
            }
            this.page.total = res.total
            this.noticeDialogVisible = false
          }
        })
        .catch()
    },
    async drawChart() {
      let data = await this.getAdminOrderWithDay()
      let stat = {}
      stat.time = []
      stat.orderMoney = []
      stat.orderNum = []
      for (let record of data) {
        stat.time.push(record.stat_time)
        stat.orderMoney.push(record.xdje)
        stat.orderNum.push(record.xdbs)
      }
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById("main"))
      // 指定图表的配置项和数据
      let option = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["下单金额", "下单数"],
        },
        xAxis: {
          show: true,
          data: stat.time,
        },
        yAxis: {
          show: false,
        },
        series: [
          {
            name: "下单金额",
            type: "line",
            smooth: true,
            showSymbol: false,
            data: stat.orderMoney,
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 4,
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#8071c9", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#a5c1f3", // 100% 处的颜色
                      },
                    ],
                    globalCoord: false,
                  },
                },
              },
            },
          },
          {
            name: "下单数",
            type: "line",
            smooth: true,
            showSymbol: false,
            data: stat.orderNum,
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 4,
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#295912", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#00b78d", // 100% 处的颜色
                      },
                    ],
                    globalCoord: false,
                  },
                },
              },
            },
          },
        ],
        color: ["#8071c9"],
      }
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option)
    },
    noticeClick(row, col, e) {
      this.noticeDialogData.title = row.noticeTitle
      this.noticeDialogData.content = row.content
      this.noticeDialogData.createTime = row.createTime
      this.noticeDialogVisible = true
    },
    async getAdminChargeWithDay() {
      let result = await getAdminChargeWithDay({ type: this.rankDate })
      this.chargeRankList = result.data
      this.chargeRankListBak = result.data
    },
    async getAdminMerchantWithDay() {
      let result = await getAdminMerchantWithDay({ type: this.rankDate })
      this.merchantRankList = result.data
      this.merchantRankListBak = result.data
    },
    getRankStat() {
      this.searchText = ""
      if (this.activeName == "charge") {
        this.getAdminChargeWithDay()
      } else {
        this.getAdminMerchantWithDay()
      }
    },
    async getAdminOrderWithDay() {
      let result = await getAdminOrderWithDay({ type: this.orderDate })
      return result.data
    },
    convert(type) {
      let typeName = "未知"
      switch (type) {
        case "1":
          typeName = "通知"
          break
        case "2":
          typeName = "公告"
          break
        default:
          break
      }
      return typeName
    },
    async getListNew() {
      this.elNew.background = "red"
      this.elNew.color = "white"
      this.elHot.background = ""
      this.elHot.color = ""
      this.choosed = "1"
      let result = await listNew({ pageNum: 1, pageSize: 10 })
      let newGoods = []
      let array = []
      for (let record of result.rows) {
        array.push(record)
        if (array.length == 6) {
          newGoods.push(this._.clone(array))
          array = []
        }
      }
      if (array.length > 0) {
        newGoods.push(array)
      }
      this.goodsData = newGoods
      this.fixProduct()
    },
    async getListSale() {
      this.elHot.background = "red"
      this.elHot.color = "white"
      this.elNew.background = ""
      this.elNew.color = ""
      this.choosed = "0"
      let result = await listSale({ pageNum: 1, pageSize: 10 })
      let array = []
      let goods = []
      for (let record of result.rows) {
        array.push(record)
        if (array.length == 6) {
          goods.push(this._.clone(array))
          array = []
        }
      }
      if (array.length > 0) {
        goods.push(array)
      }
      this.goodsData = goods
      this.fixProduct()
    },
    handleChangeStatDate() {
      this.getStat()
    },
    fixProduct() {
      this.$nextTick(() => {
        let dom = document.querySelector(
          ".carousel-item .product .product-image"
        )
        if (dom) {
          this.imageHeight =
            document.querySelector(".product-image").width + "px"
        }
      })
    },
    handleClickHotProduct() {
      this.productMenu = 9
      this.getListSale()
    },
    handleClickNewProduct() {
      this.productMenu = 1
      this.getListNew()
    },
    async getOrderNumWithDay(type) {
      let orderStat = await getOrderNumWithDay({ type })
      this.orderStat = orderStat.data
    },
    async getIncomeStat() {
      let incomeStat = await getIncomeStat({ type: this.selection })
      this.incomeStat = incomeStat.data
    },

    async getStat() {
      getAdminStatWithDay({
        type: this.statDate,
      }).then((result) => {
        this.stat = result.data
      })
    },
    async getTodoStat() {
      getAdminTodoWithDay({}).then((result) => {
        this.todoStat = result.data
      })
    },
    handleJumpProduct(row) {
      let routeUrl = this.$router.resolve({
        path: "/product",
        query: { pId: row.pId },
      })
      window.open(routeUrl.href, "_blank")
    },
    handleJump(path, params) {
      this.$router.push({ path: path, query: params })
    },
    async getHint() {
      let changeResult = await listRecharge({ checkStatus: "01" })
      if (changeResult.total > 0) {
        this.msgError(`您有${changeResult.total}笔充值申请需要处理`)
      }
      let withdrawResult = await listWithdraw({ checkStatus: "01" })
      if (withdrawResult.total > 0) {
        this.msgError(`您有${withdrawResult.total}笔提现申请需要处理`)
      }
    },
    handleChangeText() {
      if (this.activeName == "charge") {
        this.chargeRankList = this.chargeRankListBak.filter((record) => {
          if (record.userName.indexOf(this.searchText) > -1) {
            return true
          }
          return false
        })
      } else {
        this.merchantRankList = this.merchantRankListBak.filter((record) => {
          if (record.userName.indexOf(this.searchText) > -1) {
            return true
          }
          return false
        })
      }
    },
  },
  mounted() {
    this.loadNoticeData()
    this.drawChart()
    this.productMenu = 9
    this.handleClickHotProduct()
    this.getOrderNumWithDay("1")
    this.getStat()
    this.getTodoStat()
    this.getRankStat()
    this.getAdminOrderWithDay()

    this.$nextTick(()=>{
      this.getTableWidth()
    })
    // this.getIncomeStat()
  },
}
</script>

<style lang="scss" scoped>
.main {
  width: calc(100%);
  float: left;
  font-size: 12px;
  min-width: 900px;
  background: #f2f2f4;
  padding: 0 0 0 0px;
  margin: 0 0 0 -16px;
  height: 100%;
}
.todo {
  padding: 10px 8px 0 8px;
}
.title {
  position: relative;
  font-size: 14px;
  color: #333;
  font-weight: bold;
  margin: 0 0 8px 0;
  overflow: hidden;
}
.todo-content {
  padding: 32px 0px 0 0;
  display: flex;
}

.todo-item {
  flex: 1;
  text-align: center;
  position: relative;
  border: 1px solid #ddd;
  padding: 12px 12px;
  border-radius: 5px;
  background: #ffffff;
}

.todo-item-title {
  font-size: 14px;
  font-weight: normal;
  color: #8f8f90;
  float: left;
  color: #333;
  margin: 21px 0 0 0;
}

.stats .todo-item {
  height: 96px;
  text-align: left;
  padding: 14px 12px 16px 16px;
  box-sizing: border-box;
}

.stats .todo-item-title {
  float: inherit;
  margin: 0;
  color: #aaa;
  img {
    float: left;
    margin: 2px 5px 0 0;
  }
}
.stats .todo-item-num {
  float: inherit;
}
.todo-item-icon {
  width: 67px;
  height: 67px;
  float: left;
  img {
    width: 85%;
    height: 85%;
  }
}
.todo-item-num {
  margin: 5px 8px 0 0;
  font-size: 24px;
  float: right;
}
.line {
  position: absolute;
  right: 0px;
  border-right: 1px solid #aaaaaa;
  height: 36px;
  top: 20px;
}
.stats {
  margin: 3px 0 0 0;
  padding: 3px 0px 18px 0px;
  margin-top: 14px;
}
.stats-content {
  display: flex;
}
.stats-item {
  flex: 1;
  text-align: left;
  position: relative;
  margin-right: 8px;
  padding: 16px;
  border-radius: 12px;
  .item-title {
    font-size: 14px;
  }
  .item-num {
    margin: 8px 0 0 0;
    .item-num-font {
      font-size: 22px;
    }
  }
  .item-detail {
    margin-top: 8px;
  }
}
.bg1 {
  background: #feefe6;
}
.bg2 {
  background: #edf9f8;
}
.bg3 {
  background: #f2ecfe;
}
.bg4 {
  background: #eef9ed;
}
.mr16 {
  margin-right: 16px;
}
.item-image {
  position: absolute;
  right: 12px;
  top: 16px;
  img {
    width: 65px;
  }
}
.warn {
  background: #fff8e6;
  padding: 8px 8px;
  margin: 0 25px 0 8px;
  border: 1px solid #fee1b1;
  color: #ff8f21;
}
.notice {
  padding: 4px 8px 8px 8px;
  margin-top: 8px;

  ::v-deep .cell {
    font-size: 14px;
  }
  ::v-deep td {
    padding: 12px 0;
  }
}
.menu-list {
  overflow: hidden;
}
.product-image {
  cursor: pointer;
}
.product-menu {
  padding: 0 19px 8px 4px;
  overflow: hidden;
  text-align: center;
  .menu-item {
    float: left;
    margin-right: 60px;
    font-size: 14px;
    line-height: 24px;
    position: relative;
    cursor: pointer;
    .img1 {
      position: absolute;
      right: -16px;
      height: 16px;
      top: 3px;
    }
    .img2 {
      position: absolute;
      right: -32px;
      height: 12px;
      top: 6px;
    }
  }
  .selected {
    border-bottom: 3px solid #ff8f21;
    color: #ff8f21;
    font-weight: bold;
  }
}
.product-menu > div {
  overflow: hidden;
  margin: 0 auto;
  display: inline-block;
}

.order-num {
  font-size: 20px;
  color: red;
  margin: 0 0 0 0;
}
.fba-num {
  font-size: 14px;
  margin: 0 0 0 8px;
  font-weight: bold;
}

.red {
  color: red;
  font-weight: bold;
}

.product-detail {
  padding: 0 0 16px 8px;
  margin: 0 20px 0 0;
  overflow: hidden;
  display: flex;

  .carousel {
    width: 100%;
    ::v-deep .product:first-child {
      margin: 0 9px 0 0;
    }
    ::v-deep .product:last-child {
      margin: 0 0 0 9px;
    }
    .carousel-item {
      padding: 4px 0px 2px 2px;
    }
    .product {
      width: calc(16.66% - 16px);
      float: left;
      box-sizing: border-box;
      margin: 0 9px 0 9px;
      background: #ffffff;
      border-radius: 12px;
      line-height: 30px;
      overflow: hidden;
      // border: 1px solid transparent;
      img {
        width: calc(16.66% - 16px);
      }

      .unit {
        font-size: 12px;
      }
      .image {
        padding: 0;
      }
      .text {
        overflow: hidden;
      }
      .product-title {
        font-size: 14px;
        font-weight: bold;
        box-sizing: border-box;
      }
      .price {
        float: left;
        font-size: 16px;
        font-weight: bold;
      }
      .date {
        float: right;
      }
    }
    .product:hover {
      cursor: auto;
      color: #000 !important;
      transition: all 0.35s;
    }
    ::v-deep .el-carousel__button {
      width: 7px;
      height: 7px;
      border-radius: 12px;
      background: #8f8f90;
    }
    ::v-deep .el-carousel__indicator.is-active button {
      background: #4934b2;
    }
  }
  ::v-deep .el-carousel__container {
    height: 380px;
  }
}
.pager {
  text-align: center;
  margin: 4px 0 0 0;
}
.notice {
  display: flex;
  margin: 8px 16px 0 0;
  .revenue {
    background: #ffffff;
    padding: 16px 0px 5px 16px;
    height: 280px;
    .top {
    }
    .content {
      display: flex;
      height: 160px;
    }
  }
  .system-notice {
    .content {
      background: #ffffff;
      padding: 16px 8px 9px 8px;
      height: 150px;
    }
  }
}

.menu-item {
  cursor: pointer;
}

.notice ::v-deep .el-table__row {
  font-size: 14px;
}
.tabs_style {
  position: relative;
  width: calc(100% - 16px);
  text-align: center;
}
.tabs_style::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background-color: #e6e6e6;
}
.tab_style {
  position: relative;
  display: inline-block;
  width: 165px;
  padding: 8px 0 16px 0;
  font-size: 14px;
  color: #333;
  vertical-align: top;
  cursor: pointer;
  margin-bottom: 0px;
}
.tabs_style .tab_style {
  color: #333333;
}
.tab_style.active {
  color: #4934b2;
}
.tab_style.active::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background-color: #4934b2;
  z-index: 2;
}

.tab_style.active::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -6px;
  left: 50%;
  width: 12px;
  height: 12px;
  margin-left: -6px;
  border-style: solid;
  border-width: 1px;
  border-top-color: #4934b2;
  border-left-color: #4934b2;
  border-right-color: #f5f6f7;
  border-bottom-color: #f5f6f7;
  border-bottom-color: #f5f6f7;
  border-bottom-color: #f5f6f7;
  border-bottom-color: #f5f6f7;
  border-bottom-color: #f5f6f7;
  border-bottom-color: #f5f6f7;
  background-color: #f5f6f7;
  z-index: 3;
  transform: rotate(45deg);
}
.banner-wrap {
  display: flex;
  margin: 0px 16px 0 0;
  padding: 0 8px 5px 8px;

  .swiper-wrapper {
    height: 129px;
    flex: 1 1 0%;
    margin: 0px 20px 0px 0px;

    a {
      width: 100%;
      height: 129px;
      display: inline-block;
    }
    a {
      background-position: center;
      border-radius: 12px;
    }
  }
}

.notice-dialog ::v-deep .el-dialog__body {
  padding: 0 0 0 8px !important;
}

.white {
  color: #ffffff;
}
.banner {
  cursor: pointer;
}
.banner1 {
  background: url("../../assets/images/user/banner1.png") no-repeat;
  background-size: 100% 100%;
}
.banner2 {
  background: url("../../assets/images/user/banner2.png") no-repeat;
  background-size: 100% 100%;
}
.detail {
  padding: 4px 12px 0 12px;
}
.product:hover {
  cursor: auto;
  color: #000 !important;
  box-shadow: rgb(0 0 0 / 5%) 2px 6px 10px 5px;
  // border: 1px solid #dddddd !important;
}
</style>
